import createModel, {
  Any,
  Mapped,
  Optional,
  Silent,
} from "@/__main__/data-model.mjs";

const TeamUser = {
  gameCustomization: {
    GoldenSpatulaClub: String,
    Regalia: String,
    challenges: Silent(String),
    championOwned: String,
    perks: Silent(String),
    ranked: String,
    statstones: Silent(String),
    summonerBanner: Silent(String),
    summonerEmotes: String,
    summonerTrophy: String,
    vintageSkin: String,
    wardSkin: Silent(String),
  },
  lastSelectedSkinIndex: Number,
  locale: Any,
  profileIconId: Number,
  puuid: String,
  selectedPosition: String,
  selectedRole: String,
  summonerId: Number,
  summonerInternalName: String,
  summonerName: String,
  teamOwner: Boolean,
  teamParticipantId: Number,
  /* TFT doesn't have these */
  accountId: Optional(Number),
  adjustmentFlags: Optional(Number),
  botDifficulty: Optional(String),
  championId: Optional(Number),
  clientInSynch: Optional(Boolean),
  index: Optional(Number),
  minor: Optional(Boolean),
  originalPlatformId: Optional(Number),
  originalAccountNumber: Optional(Number),
  partnerId: Optional(String),
  pickMode: Optional(Number),
  pickTurn: Optional(Number),
  queueRating: Optional(Number),
  rankedTeamGuest: Optional(Boolean),
  teamRating: Optional(Number),
  timeAddedToQueue: Optional(Number),
  timeChampionSelectStart: Optional(Number),
  timeGameCreated: Optional(Number),
  voterRating: Optional(Number),
};

const apiModelValidation = createModel({
  lcu: {
    gameClient: {
      observerServerIp: String,
      observerServerPort: Number,
      running: Boolean,
      serverIp: String,
      serverPort: Number,
      visible: Boolean,
    },
    gameData: {
      gameId: Number,
      gameName: String,
      isCustomGame: Boolean,
      password: String,
      playerChampionSelections: [
        {
          championId: Number,
          selectedSkinIndex: Number,
          spell1Id: Number,
          spell2Id: Number,
          puuid: String,
          summonerInternalName: Optional(String),
        },
      ],
      queue: {
        allowablePremadeSizes: [Number],
        areFreeChampionsAllowed: Boolean,
        assetMutator: String,
        category: String,
        championsRequiredToPlay: Number,
        description: String,
        detailedDescription: String,
        gameMode: String,
        gameTypeConfig: {
          advancedLearningQuests: Boolean,
          allowTrades: Boolean,
          banMode: String,
          banTimerDuration: Number,
          battleBoost: Boolean,
          crossTeamChampionPool: Boolean,
          deathMatch: Boolean,
          doNotRemove: Boolean,
          duplicatePick: Boolean,
          exclusivePick: Boolean,
          id: Number,
          learningQuests: Boolean,
          mainPickTimerDuration: Number,
          maxAllowableBans: Number,
          name: String,
          onboardCoopBeginner: Boolean,
          pickMode: String,
          postPickTimerDuration: Number,
          reroll: Boolean,
          teamChampionPool: Boolean,
        },
        id: Number,
        isRanked: Boolean,
        isTeamBuilderManaged: Boolean,
        lastToggledOffTime: Number,
        lastToggledOnTime: Number,
        mapId: Number,
        maximumParticipantListSize: Number,
        minLevel: Number,
        minimumParticipantListSize: Number,
        name: String,
        numPlayersPerTeam: Number,
        queueAvailability: String,
        queueRewards: {
          isChampionPointsEnabled: Boolean,
          isIpEnabled: Boolean,
          isXpEnabled: Boolean,
          partySizeIpRewards: [Any],
        },
        removalFromGameAllowed: Boolean,
        removalFromGameDelayMinutes: Number,
        shortName: String,
        showPositionSelector: Boolean,
        spectatorEnabled: Boolean,
        type: String,
        /* TFT doesn't have these */
        isTeamOnly: Optional(Boolean),
        maxLevel: Optional(Number),
        maxSummonerLevelForFirstWinOfTheDay: Optional(Number),
      },
      spectatorsAllowed: Boolean,
      teamOne: [TeamUser],
      teamTwo: [TeamUser],
    },
    gameDodge: { dodgeIds: [Any], phase: String, state: String },
    map: {
      assets: {
        "game-select-icon-active": String,
        "game-select-icon-active-video": Optional(String),
        "game-select-icon-default": String,
        "game-select-icon-disabled": String,
        "game-select-icon-hover": String,
        "game-select-icon-intro-video": Optional(String),
        "gameflow-background": String,
        "gameselect-button-hover-sound": String,
        "icon-defeat": String,
        "icon-empty": String,
        "icon-victory": String,
        "music-inqueue-loop-sound": String,
        "parties-background": String,
        "postgame-ambience-loop-sound": Optional(String),
        "ready-check-background": String,
        "sfx-ambience-pregame-loop-sound": String,
        /* TFT */
        set_announcement_modal: Optional(String),
        "set-announcement-background": Optional(String),
        "set-announcement-icon": Optional(String),
        "set-announcement-ok-button-clicked": Optional(String),
        "set-announcement-ok-button-default": Optional(String),
        "set-announcement-ok-button-hover": Optional(String),
        "set-announcement-separator-icon": Optional(String),
        "set-event-5YA-Title-Logo": Optional(String),
        /* TFT does or doesn't have these */
        "champ-select-background-sound": Optional(String),
        "champ-select-flyout-background": Optional(String),
        "champ-select-skip-bg": Optional(String),
        "champ-select-planning-intro": Optional(String),
        "gameflow-background-dark": Optional(String),
        "icon-defeat-v2": Optional(String),
        "icon-defeat-video": Optional(String),
        "icon-hover": Optional(String),
        "icon-leaver": Optional(String),
        "icon-leaver-v2": Optional(String),
        "icon-loss-forgiven-v2": Optional(String),
        "icon-v2": Optional(String),
        "icon-victory-video": Optional(String),
        "map-north": Optional(String),
        "map-south": Optional(String),
        "ready-check-background-sound": Optional(String),
        "social-icon-leaver": Optional(String),
        "social-icon-victory": Optional(String),
      },
      c: {},
      categorizedContentBundles: {
        set_announcement_modal: Optional(
          Mapped({
            key: String,
            value: Mapped({
              key: String,
              value: String,
            }),
          }),
        ),
      },
      description: String,
      gameMode: String,
      gameModeName: String,
      gameModeShortName: String,
      gameMutator: String,
      id: Number,
      isRGM: Boolean,
      mapStringId: String,
      name: String,
      perPositionDisallowedSummonerSpells: {},
      perPositionRequiredSummonerSpells: {},
      platformId: String,
      platformName: String,
      properties: { suppressRunesMasteriesPerks: Boolean },
    },
    phase: String,
  },
  region: String,
  localSummonerId: Number,
});

function transform(data) {
  return apiModelValidation(data);
}

export async function fixture() {
  const { default: INPROGRESS_FIXTURE } = await import(
    "@/game-lol/fixtures/lol-inprogress-fixture.json"
  );
  return {
    lcu: INPROGRESS_FIXTURE,
    region: "na1",
    localSummonerId: 21154579,
  };
}

export default transform;
